input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: searchfield-cancel-button;
}

input[type="search"]::-webkit-search-cancel-button:hover {
  cursor: pointer;
}

input[type="search"] {
  -webkit-appearance: none;
}
