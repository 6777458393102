@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  @media only screen and (max-width: 1023px) {
    .tree {
      max-width: 200px;
    }
    .sizing_parent {
      flex-direction: column;
    }
  }

  @media only screen and (max-width: 425px) {
    .tree {
      @apply pb-0;
    }

    .logo_mobile {
      @apply text-lg;
    }
    .logo {
      @apply mt-2;
    }
    .subheading {
      @apply mb-7 text-xl;
    }
    .caption {
      @apply mb-7 text-base;
    }
  }
}
