* {
  box-sizing: border-box;
}

body {
  background-color: #0073cb;
}
.loader {
  width: 10rem;
  height: 10rem;
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.loader-inner {
  width: 100%;
  height: 100%;
  border: 1rem solid #0073cb;
  border-radius: 50%;
  border-bottom-color: #0073cb;
  border-top-color: #0073cb;
  border-left-color: #0073cb;
  border-right-color: #0073cb;

  animation: rotate 3.2s linear;
}
@keyframes rotate {
  0% {
    transform: scale(1) rotate(360deg);
  }
  50% {
    transform: scale(0.5) rotate(-360deg);
  }
  100% {
    transform: scale(1) rotate(360deg);
  }
}

.quarter-circle-bottom-right {
  width: 5rem;
  height: 5rem;

  border-radius: 10rem 0 0 0;
  position: relative;
  bottom: 150px;
  right: 1rem;
}

.rectangle-chunk {
  width: 6rem;
  height: 3rem;

  position: relative;
  top: 3.75rem;
  left: 5rem;
}
