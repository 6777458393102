@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .wrapper {
    @apply bg-secondary dark:bg-primary min-h-screen;
  }
}
.summary {
  position: fixed;
  top: calc(50% - 160px); 
  left: calc(50% - 192px)
}
